/* eslint-disable import/extensions */
/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router.js';
import dynamic from 'next/dynamic';
import { useEffect, useMemo } from 'react';
// import { motion } from 'framer-motion';
import MobileNav from '../mobilenav';
import { PromoHeaderBannerWithPosthog, UnpaidInvoiceHeaderBanner, RenewalInvoiceHeaderBanner } from '../miscellaneous/promo_header_banner/index.js';
import useUserAgent from '../../hooks/useUserAgent.js';
import classnames from '../../utils/classnames';
import Navbar from '../navbar/index.js';
import Versioning from '../../utils/Versioning.js';

const dynamicComponent = {
  Footer: dynamic(() => import('../footer/index.js'), {
    ssr: false,
  }),
  PromoHeaderBanner: dynamic(() => import('../miscellaneous/promo_header_banner/index.js'), {
    ssr: false,
  }),
  Navbar: dynamic(() => import('../navbar/index.js'), {
    ssr: false,
  }),
};

export default function MainLayout({ children, className, mode, headerClassName }) {
  const showLogin = useSelector((state) => state.popup.login);
  const router = useRouter();
  const dontShowMobileNav = useMemo(() => [
    '/marketplace/[slug]',
    '/creation/[slug]',
    // '/marketplace/search',
    // '/search',
    '/account/[slug]',
  ], []);
  const dontShowHeader = useMemo(() => [
    '/creation/[slug]',
    '/marketplace/search',
    '/search'
  ], []);
  const { isMobile, screenWidthLessThan } = useUserAgent();
  const currentPathName = useMemo(() => router.pathname.split('/')[1], [router.pathname]);
  const isShowMobileNavbar = useMemo(() => (
    (!dontShowMobileNav.includes(router.pathname) && (screenWidthLessThan(1200) || isMobile.Assemblr()))
  ), [isMobile.Assemblr(), router.pathname, screenWidthLessThan(1200)]);
  const allowedSlug = ['pro', 'premium', 'free', 'owned', 'plan', 'invoices', 'setting'];

  useEffect(() => {
    console.log('router', currentPathName);
  }, [currentPathName]);

  return (
    <>
      <div
        id="sticky_navbar"
        className={`sticky_navbar ${(dontShowHeader.includes(router.pathname) && !showLogin) ? 'hideMobile' : ''}`}
      >
        <dynamicComponent.PromoHeaderBanner />
        {!isMobile.Assemblr() && <PromoHeaderBannerWithPosthog />}
        {!isMobile.Assemblr() && <UnpaidInvoiceHeaderBanner />}
        {!isMobile.Assemblr() && <RenewalInvoiceHeaderBanner />}
        {process.env.NODE_ENV === 'development' ? <dynamicComponent.Navbar mode={mode} /> : <Navbar mode={mode} />}
      </div>
      {Versioning.TIERED_SUBSCRIPTION() && <header id="header_container" className={classnames('sticky top-0 w-full', headerClassName)} />}
      <div
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // exit={{ opacity: 0 }}
        // transition={{ duration: 0.2 }}
        className={classnames(
          'main_content',
          currentPathName === 'explore' && 'explore',
          currentPathName === 'search' && 'global-search',
          className,
        )}
      >
        {children}
      </div>
      <div className="tablet_up">
        <dynamicComponent.Footer />
      </div>
      {allowedSlug.includes(router.query.slug) ? (
        <div id="mobile_nav" className="fixed_mobile_nav mobile">
          <MobileNav />
        </div>
      ) : null}
      {isShowMobileNavbar && (
        <div
          id="mobile_nav"
          className="fixed_mobile_nav"
        >
          <MobileNav />
        </div>
      )}
    </>
  );
}
